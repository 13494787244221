// 抽奖皮肤组件 公共方法
import { contactDataApi, receiveApi, getQrcodeApi, receivecouponApi } from "@/api/lottery";
import { wxConfig } from "@/utils/wxCodeAuth";
import prize from "@/views/lottery/prize";
export const publicMixin = {
    props: {
        prizeData: Array,  //奖品参数
        weChatUserNews: Object,//微信用户信息
    },
    components: {
        prize,
    },
    data() {
        return {
            debounceTimer: null,
        }
    },
    created() {
        //  获取参数
        this.id = this.$route.query.id;
        this.source = this.$route.query.source;
        this.employee_id = this.$route.query.employee_id;

    },
    mounted() {

    },
    methods: {
        receivePrize() {
            //表示领奖后的重定向回来
            // 假设获取到了 openid将 openid 存储到本地存储中
            localStorage.setItem('openid', this.$route.query.openid);
            // 设置过期时间为一天后的时间戳
            const expireTime = new Date().getTime() + 24 * 60 * 60 * 1000;
            localStorage.setItem('openidExpireTime', expireTime.toString());
            receivecouponApi({ id: this.$route.query.prizeId, openid: this.$route.query.openid }).then((res) => {
                if (res.code == 200) {
                    if (this.clientData.win_list && this.clientData.win_list.length != 0) {
                        const filteredList = this.clientData.win_list.filter(item => item.id == this.$route.query.prizeId);
                        this.$refs.prize.show(filteredList[0], true);
                        this.getClientData()
                    }

                }
            });
        },
        //  获取抽奖数据
        getClientData() {
            this.isLoading = true;
            let params = {
                id: this.id,
                union_id: this.weChatUserNews.unionid,
                nickname: this.weChatUserNews.nickname,
                avatar: this.weChatUserNews.headimgurl,
                city: this.weChatUserNews.city,
                source: this.source,
            };
            contactDataApi(params).then((res) => {
                if (this.weChatUserNews.nickname == "微信用户") {
                    this.isLoading = false;
                    this.showTips = true;
                    return;
                }
                this.defaultPage = res.data.lottery.type;
                document.title = res.data.lottery.name;
                this.clientData = res.data;
                this.isLoading = false;
                //判断是否属于我行客户
                if (
                    this.clientData.point.is_employee_contact == 0 &&
                    this.clientData.point.is_contact == 1
                ) {
                    getQrcodeApi({ id: this.id, employee_id: this.employee_id }).then(
                        (res) => {
                            console.log("__", res);
                            this.qrCode = res.data.qr_code;
                            this.showCode = true;
                        }
                    );
                    return;
                }
                res.data.prize.prizeSet.forEach((item, index) => {
                    this.clientData.win_list.forEach((obj, idx) => {
                        if (item.name == obj.prizeName) {
                            if (item.type == 2) {
                                obj.receiveQr = item.image;
                            }
                        }
                    });
                });
                this.corp = res.data.corp;
                //  处理奖品设置数据
                this.prizeSetNews = this.clientData.prize.prizeSet;
                //   this.handSetprize(this.prizeSetNews);
                // const routeOpenid = this.$route.query.openid;
                // const localStorageOpenid = localStorage.getItem('openid');
                // const localStorageExpireTime = localStorage.getItem('openidExpireTime');
                // if (routeOpenid && localStorageOpenid !== null && localStorageOpenid !== undefined) {
                //     // 如果 this.$route.query.openid 和 localStorage 中的 openid 都存在，则检查过期时间
                //     const currentTime = new Date().getTime();
                //     if (localStorageExpireTime && currentTime <= Number(localStorageExpireTime)) {
                //         // openid 未过期，可以执行其他逻辑或初始化操作
                //         // ...
                //     } else {
                //         // openid 已过期，清除本地存储中的 openid 和过期时间戳
                //         localStorage.removeItem('openid');
                //         localStorage.removeItem('openidExpireTime');
                //         // 执行其他逻辑或重新获取 openid 的操作
                //         // ...
                //     }
                // } else if (routeOpenid && !localStorageOpenid) {
                //     // 如果 this.$route.query.openid 存在，但 localStorage 中的 openid 不存在，则执行接口请求
                //     this.receivePrize();
                // }
                //分享内容
                this.shareOption = {
                    title: res.data.lottery.name, // 分享标题
                    desc: res.data.lottery.description, // 分享描述
                    link: this.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
                    imgUrl: res.data.lottery.cover, // 分享图标
                    success: function () {
                        // 用户点击了分享后执行的回调函数
                    },
                };

                let that = this;
                let shareFunction = function () {
                    //需在用户可能点击分享按钮前就先调用
                    wx.updateAppMessageShareData(that.shareOption);
                    wx.updateTimelineShareData(that.shareOption);
                };

                //加载微信配置
                wxConfig(shareFunction);
            });
        },
        skip(item) {
            this.$refs.prize.show(item);
        },
        //  领取奖励
        receiveReward(item) {
            if (item.receiveStatus == 0) {
                receiveApi({
                    id: item.id,
                }).then((res) => {
                    this.$message.success("奖励领取成功");
                    this.getClientData();
                });
            }
            this.$refs.prize.show(item);
        },
        //防抖函数
        debounce(func, delay) {
            if (!this.debounceTimer) {
                func.call(this);
                this.debounceTimer = setTimeout(() => {
                  this.debounceTimer = null;
                }, delay);
              }
        },

    }
}