<template>
  <div class="page">
    <a-spin :spinning="isLoading" tip="加载中...">
      <div class="head">
        <img src="../../../static/images/fourthTitle.png" alt="">
      </div>
      <div class="draw">
        <div class="draw_box">
          <div class="box_title">奖品享不停 惊喜扭一扭</div>
          <div class="box_cen">
            <div v-for="item in 10" :key="item" :class="isA?'wieyi_'+(item):''" class="ball" :style="randomRgb()">
            </div>
          </div>
          <div class="drawball" :style="randomRgbNext()" v-if="drawballShow"></div>
          <div class="box_btn" @click="openBtn()"></div>
        </div>
      </div>
      <div class="info">
        <div class="info_box">
          <div v-if="clientData.message&&clientData.message.length!=0">
            <div class="list_box">
              <span>{{ clientData.message.nickname }}</span>
              <span>{{ clientData.message.prize_name }}</span>
            </div>
          </div>
          <div class="recordBox" v-else>暂无中奖记录</div>
        </div>
      </div>
      <div class="record_box">
        <div class="record_mar">
          <div v-if="clientData.win_list&&clientData.win_list.length!=0">
            <div v-for="(item,index) of clientData.win_list" :key="index">
              <div class="record_list">
                <div class="box_title">{{item.createdAt}}</div>
                <div class="box_info">
                  <div class="info_left">
                    <img :src="item.prizeImage || item.receiveQr" alt="">
                    <span>{{ item.prizeName }}</span>
                  </div>
                  <div v-if="item.receiveType == 3 ||item.receiveType == 4||item.receiveType == 6">
                    <div class="info_right" v-if="item.receiveStatus == 0" @click="skip(item)">兑换
                    </div>
                    <div class="info_right" style="background: #C0C0C0;color: #ffffff;" v-else>已兑换</div>
                  </div>
                  <div v-else>
                    <div class="info_right" v-if="item.receiveStatus == 0" @click="receiveReward(item)">兑换</div>
                    <div class="info_right" v-else style="background: #C0C0C0;color: #ffffff;" @click="receiveReward(item)">已兑换</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <van-empty v-else description="暂无记录" />
        </div>
      </div>
      <div style="width: 100%;height: 40px;"></div>
      <div class="pop_up">
        <prize ref="prize" @childEvent="getClientData"/>
      </div>
    </a-spin>
  </div>
</template>

<script>
  import { contactLotteryApi } from "@/api/lottery";
  import { publicMixin } from "@/mixin/index.js"
  export default {
    mixins: [publicMixin],
    data() {
      return {
        isLoading: false,
        list: [],
        isA: false,
        drawballShow: false,
        randomRgb(item) {
          let R = Math.floor(Math.random() * 256);
          let G = Math.floor(Math.random() * 256);
          let B = Math.floor(Math.random() * 256);
          let imgs = [require('../../../static/images/fourth1.png'), require('../../../static/images/fourth2.png'), require('../../../static/images/fourth3.png'), require('../../../static/images/fourth4.png'), require('../../../static/images/fourth5.png'), require('../../../static/images/fourth6.png'), require('../../../static/images/fourth7.png'), require('../../../static/images/fourth8.png'), require('../../../static/images/fourth9.png'), require('../../../static/images/fourth10.png')]
          let imgName = imgs[Math.floor(Math.random() * 10) + 1] //进行计算随机
          let style = "background-image:url('" + imgName + "');  background-size: cover;position: absolute;bottom:20px;left:" + (Math.floor(Math.random() * (210 - 15)) + 15) + "px;";
          return style
        },
        //中奖随机球
        randomRgbNext() {
          let imgs = [require('../../../static/images/fourth1.png'), require('../../../static/images/fourth2.png'), require('../../../static/images/fourth3.png'), require('../../../static/images/fourth4.png'), require('../../../static/images/fourth5.png'), require('../../../static/images/fourth6.png'), require('../../../static/images/fourth7.png'), require('../../../static/images/fourth8.png'), require('../../../static/images/fourth9.png'), require('../../../static/images/fourth10.png')]
          let imgName = imgs[Math.floor(Math.random() * 10) + 1] //进行计算随机
          let style = "background-image:url('" + imgName + "');  background-size: cover;";
          return style
        },
        clientData: {}, //  客户数据
        prizeSetNews: [],//  设置的奖品,
        drawState: false,//是否抽奖
        // source: 'from_pc',

      }
    },
    watch: {
      prizeData: { // 监控该变量，重新赋值并刷新图表
        handler(newVal, oldVal) {
          this.prizeSetNews = newVal
        },
        deep: true // 必须设置
      },
    },
    created() {
      this.id = this.$route.query.id;
      this.getClientData()
    },
    methods: {
      openBtn(){
          this.debounce(this.openBtnNext, 3000);
      },
      //开始抽奖
      openBtnNext() {
        if (this.drawState) return
        this.drawState = true
        //调取接口获取中奖数据
        contactLotteryApi({
          id: this.id,
          union_id: this.weChatUserNews.unionid,
          nickname: this.weChatUserNews.nickname,
        }).then((res) => {
          if (res.code == 200) {
            this.isA = true
            this.drawballShow = false
            let prizeInfo = {};
            this.prizeSetNews.forEach((item, index) => {
              if (item.name == res.data.prize_name) {
                prizeInfo = item;
              }
            });
            setTimeout(() => {
              this.isA = false
              this.drawballShow = true
              let prizeData = {
                openId: this.openId,
                receiveType: res.data.receive_type,
                prizeName: res.data.prize_name,
                receiveCode: res.data.receive_code,
                receiveQr: res.data.receive_qr,
                description: res.data.description,
                record_id: res.data.record_id,
                nickname: this.weChatUserNews.nickname,
                headimgurl: this.weChatUserNews.headimgurl,
                money:res.data.money,
              };
              // if (prizeInfo.type == 2) {
                prizeData.receiveQr = prizeInfo.image;
              // }
              this.$refs.prize.show(prizeData);
              this.getClientData();
            }, 1500)
            setTimeout(() => {
              this.drawState = false
              this.drawballShow = false
            }, 3000)
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  /deep/.van-empty{
        padding: 0px;
   }
  .page {
    width: 100%;
    height: 100%;
    background-image: url("../../../static/images/fourthBack.png");
    background-size: cover;

    .head {
      width: 100%;
      height: 136px;
      background-image: url("../../../static/images/fouerthHeadBack.png");
      background-size: cover;
      position: relative;

      img {
        width: 281px;
        height: 58px;
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .draw {
      padding: 0 25px;

      .draw_box {
        width: 100%;
        height: 520px;
        background-image: url("../../../static/images/fourthBox.png");
        background-size: 100% 100%;
        padding: 0 27px;
        position: relative;

        .box_title {
          width: 100%;
          height: 31px;
          background: #F06060;
          border-radius: 5px;
          position: relative;
          top: 145px;
          font-size: 18px;
          font-weight: 400;
          color: #FFFFFF;
          text-align: center;
          line-height: 33px;
        }

        .box_cen {
          width: 100%;
          height: 231px;
          background-image: url("../../../static/images/fourthCen.png");
          background-size: 100% 100%;
          position: relative;
          top: 158px;

          .ball {
            width: 58px;
            height: 58px;
            border-radius: 50%;
            /* background: #F06060; */
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .drawball {
          width: 58px;
          height: 58px;
          border-radius: 50%;
          position: absolute;
          bottom: 15px;
          /* left: 60px; */
          left: 18%;
        }

        .box_btn {
          width: 131px;
          height: 71px;
          background-image: url("../../../static/images/fourthBtn.png");
          background-size: cover;
          position: absolute;
          bottom: 20px;
          right: 27px;

        }
      }
    }

    .info {
      width: 384px;
      height: 175px;
      background-image: url("../../../static/images/fourthInfo.png");
      background-size: cover;
      margin: 0 auto;
      /* margin-left: 5px; */
      margin-top: 27px;
      padding: 50px 60px 50px 60px;

      .info_box {
        .list_box {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;

          span {
            font-size: 12px;
            font-weight: 400;
            color: #FFFFFF;
          }
        }

        .recordBox {
          text-align: center;
          color: #C90605;
          font-size: 12px;
        }
      }

    }

    .record_box {
      width: 384px;
      height: 305px;
      background-image: url("../../../static/images/fourthRecord.png");
      background-size: cover;
      margin: 0 auto;
      /* margin-left: 5px; */
      margin-top: 15px;
      padding: 0 40px;
      padding-top: 70px;


      .record_mar {
        height: 180px;

        overflow: hidden;
        overflow-y: scroll;

        .record_list {

          .box_title {
            font-size: 12px;
            font-weight: 400;
            color: #FFFFFF;
          }

          .box_info {
            margin-top: 5px;
            padding: 10px 12px;
            background: #FFE5D9;
            border-radius: 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .info_left {
              display: flex;
              flex-direction: row;
              align-items: center;

              img {
                display: flex;
                flex-direction: row;
                width: 45px;
                height: 45px;
              }

              span {
                font-size: 16px;
                font-weight: 400;
                color: #DC1912;
                margin-left: 15px;
              }
            }

            .info_right {
              display: flex;

              padding: 0 20px;
              align-items: center;
              height: 28px;
              background: linear-gradient(0deg, #FEDA62, #FFE06A);
              border-radius: 14px;
              font-size: 16px;
              font-weight: 500;
              color: #EF2823;
            }
          }
        }

      }
    }
  }

  .pop_up {
    width: 100%;
    background-color: #00000094;
    position: fixed;
    top: 110%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width:370px) {
    .page .info {
      padding: 50px 30px
    }

    .page .draw .draw_box .drawball {
      left: 17%;
    }
  }

  .wieyi_1 {
    animation: around1 1.5s ease infinite;
  }

  .wieyi_2 {
    animation: around2 1.5s linear infinite;
  }

  .wieyi_3 {
    animation: around3 1.5s linear infinite;
  }

  .wieyi_4 {
    animation: around4 1.5s linear infinite;
  }

  .wieyi_5 {
    animation: around5 1.5s linear infinite;
  }

  .wieyi_6 {
    animation: around6 1.5s linear infinite;
  }

  .wieyi_7 {
    animation: around7 1.5s linear infinite;
  }

  .wieyi_8 {
    animation: around8 1.5s linear infinite;
  }

  .wieyi_9 {
    animation: around9 1.5s linear infinite;
  }

  .wieyi_10 {
    animation: around10 1.5s linear infinite;
  }

  /* 高150  */
  @keyframes around1 {
    0% {
      -webkit-transform: translate(0px, 0px)
    }

    20% {
      -webkit-transform: translate(0px, -110px)
    }

    40% {
      -webkit-transform: translate(0px, 20px)
    }

    60% {
      -webkit-transform: translate(0px, -110px)
    }

    80% {
      -webkit-transform: translate(0px, 20px)
    }

    100% {
      -webkit-transform: translate(0px, 0px)
    }
  }

  @keyframes around2 {
    0% {
      -webkit-transform: translate(0px, 0px)
    }

    20% {
      -webkit-transform: translate(10px, -110px)
    }

    40% {
      -webkit-transform: translate(0px, 20px)
    }

    60% {
      -webkit-transform: translate(10px, -110px)
    }

    80% {
      -webkit-transform: translate(0px, 20px)
    }

    100% {
      -webkit-transform: translate(0px, 0px)
    }
  }

  @keyframes around3 {
    0% {
      -webkit-transform: translate(0px, 0px)
    }

    20% {
      -webkit-transform: translate(15px, -140px)
    }

    40% {
      -webkit-transform: translate(5px, 20px)
    }

    60% {
      -webkit-transform: translate(20px, -140px)
    }

    80% {
      -webkit-transform: translate(5px, 20px)
    }

    100% {
      -webkit-transform: translate(0px, 0px)
    }
  }

  @keyframes around4 {
    0% {
      -webkit-transform: translate(0px, 0px)
    }

    20% {
      -webkit-transform: translate(45px, -80px)
    }

    40% {
      -webkit-transform: translate(25px, 20px)
    }

    60% {
      -webkit-transform: translate(30px, -120px)
    }

    80% {
      -webkit-transform: translate(15px, 30px)
    }

    100% {
      -webkit-transform: translate(0px, 0px)
    }
  }

  @keyframes around5 {
    0% {
      -webkit-transform: translate(0px, 0px)
    }

    20% {
      -webkit-transform: translate(0px, -80px)
    }

    40% {
      -webkit-transform: translate(30px, 20px)
    }

    60% {
      -webkit-transform: translate(0px, -120px)
    }

    80% {
      -webkit-transform: translate(0px, 30px)
    }

    100% {
      -webkit-transform: translate(0px, 0px)
    }
  }

  @keyframes around6 {
    0% {
      -webkit-transform: translate(0px, 0px)
    }

    20% {
      -webkit-transform: translate(40px, -20px)
    }

    40% {
      -webkit-transform: translate(10px, 20px)
    }

    60% {
      -webkit-transform: translate(0px, -120px)
    }

    80% {
      -webkit-transform: translate(70px, -80px)
    }

    100% {
      -webkit-transform: translate(0px, 0px)
    }
  }

  @keyframes around7 {
    0% {
      -webkit-transform: translate(0px, 0px)
    }

    20% {
      -webkit-transform: translate(80px, -130px)
    }

    40% {
      -webkit-transform: translate(10px, 20px)
    }

    60% {
      -webkit-transform: translate(35px, -30px)
    }

    80% {
      -webkit-transform: translate(40px, -80px)
    }

    100% {
      -webkit-transform: translate(0px, 0px)
    }
  }

  @keyframes around8 {
    0% {
      -webkit-transform: translate(0px, 0px)
    }

    20% {
      -webkit-transform: translate(120px, -150px)
    }

    40% {
      -webkit-transform: translate(60px, 20px)
    }

    60% {
      -webkit-transform: translate(35px, -150px)
    }

    80% {
      -webkit-transform: translate(80px, -80px)
    }

    100% {
      -webkit-transform: translate(0px, 0px)
    }
  }

  @keyframes around9 {
    0% {
      transform: translate(0px, 0px)
    }

    15% {
      transform: translate(70px, -120px)
    }

    30% {
      transform: translate(-10px, -10px)
    }

    45% {
      transform: translate(-30px, -110px)
    }

    60% {
      transform: translate(10px, -110px)
    }

    75% {
      transform: translate(-10px, -140px)
    }

    100% {
      -webkit-transform: translate(0px, 0px)
    }
  }

  @keyframes around10 {
    0% {
      transform: translate(0px, 0px)
    }

    15% {
      transform: translate(10px, 10px)
    }

    30% {
      transform: translate(70px, -10px)
    }

    45% {
      transform: translate(-10px, -110px)
    }

    60% {
      transform: translate(10px, -20px)
    }

    75% {
      transform: translate(-10px, -80px)
    }

    100% {
      -webkit-transform: translate(0px, 0px)
    }
  }
</style>