<template>
    <div class="page">
        <a-spin :spinning="isLoading" tip="加载中...">
            <div class="page_head">
                <img src="../../../static/images/fifthHead.png" alt="">
            </div>
            <div class="page_center">
                <img v-show="chouJiang.qiao" class="center_eag" src="../../../static/images/fifthGif.gif" alt="">
                <div v-show="!chouJiang.qiao" @click="clickOne()">
                    <img class="center_eag" src="../../../static/images/fifthEag.png" alt="">
                    <img class="center_hammer" src="../../../static/images/fifthHammer.png" alt="">
                    <img class="center_btn" src="../../../static/images/fifthBtn.png" alt="">
                </div>
            </div>
            <div class="page_info">
                <div class="info_title">
                    <span style="margin-right: 5px;"></span>
                    中奖名单
                    <span style="margin-left: 5px;"></span>
                </div>
                <div class="info_list">
                    <div v-if="clientData.message&&clientData.message.length!=0">
                        <div class="list_box">
                            <span>{{ clientData.message.nickname }}</span>
                            <span>{{ clientData.message.prize_name }}</span>
                        </div>
                    </div>
                    <div class="recordBox" v-else>暂无中奖记录</div>
                </div>
            </div>
            <div class="record_title">
                <img src="../../../static/images/fifthRecond.png" alt="">
            </div>
            <div class="record_box">
                <div class="record_mar">
                    <div v-if="clientData.win_list&&clientData.win_list.length!=0">
                        <div v-for="(item,index) of clientData.win_list" :key="index">
                            <div class="record_list">
                                <div class="box_title">{{item.createdAt}}</div>
                                <div class="box_info">
                                    <div class="info_left">
                                        <img :src="item.prizeImage || item.receiveQr" alt="">
                                        <span>{{ item.prizeName }}</span>
                                    </div>
                                    <div v-if="item.receiveType == 3 ||item.receiveType == 4||item.receiveType == 6">
                                        <div class="info_right" v-if="item.receiveStatus == 0" @click="skip(item)">兑换
                                        </div>
                                        <div class="info_right" style="background: #C0C0C0;color: #ffffff;" v-else>已兑换</div>
                                    </div>
                                    <div v-else>
                                        <div class="info_right" v-if="item.receiveStatus == 0"
                                            @click="receiveReward(item)">兑换</div>
                                        <div class="info_right" style="background: #C0C0C0;color: #ffffff;" v-else @click="receiveReward(item)">已兑换</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <van-empty v-else description="暂无记录" />

                </div>

            </div>
            <div style="width: 100%;height: 40px;"></div>
            <div class="pop_up">
                <prize ref="prize" @childEvent="getClientData"/>
            </div>

        </a-spin>
    </div>
</template>

<script>
    import { contactLotteryApi } from "@/api/lottery";
    import { publicMixin } from "@/mixin/index.js"
    export default {
        mixins: [publicMixin],
        data() {
            return {
                isLoading: false,
                chouJiang: {
                    num: 3, //默认蛋数量
                    egg: null, //敲击的哪个鸡蛋
                    qiao: false, //是否敲击
                    result: '谢谢参与', //默认结果
                    time: 2000 //多少秒后弹出结果 ms
                },
                list: [],
                clientData: {}, //  客户数据
                prizeSetNews: [],//  设置的奖品
                // source: 'from_pc'
            }
        },
        watch: {
            prizeData: { // 监控该变量，重新赋值并刷新图表
                handler(newVal, oldVal) {
                    this.prizeSetNews = newVal
                    // this.handlePrizes()
                },
                deep: true // 必须设置
            },
        },
        created() {
            this.id = this.$route.query.id;
            this.getClientData()
        },
        methods: {
            clickOne(){
                this.debounce(this.startCallbackNext, 3000);
            },
            startCallbackNext(index) {
                //调取接口获取中奖数据
                contactLotteryApi({
                    id: this.id,
                    union_id: this.weChatUserNews.unionid,
                    nickname: this.weChatUserNews.nickname,
                }).then((res) => {
                    if (res.code == 200) {
                        this.chouJiang.qiao = true
                        let prizeInfo = {};
                        this.prizeSetNews.forEach((item, index) => {
                            if (item.name == res.data.prize_name) {
                                prizeInfo = item;
                            }
                        });
                        setTimeout(() => {
                            this.chouJiang.qiao = false
                            let prizeData = {
                                openId: this.openId,
                                receiveType: res.data.receive_type,
                                prizeName: res.data.prize_name,
                                receiveCode: res.data.receive_code,
                                receiveQr: res.data.receive_qr,
                                description: res.data.description,
                                record_id: res.data.record_id,
                                nickname: this.weChatUserNews.nickname,
                                headimgurl: this.weChatUserNews.headimgurl,
                                money:res.data.money,
                            };
                            // if (prizeInfo.type == 2) {
                                prizeData.receiveQr = prizeInfo.image;
                            // }
                            this.$refs.prize.show(prizeData);
                            this.getClientData();
                        }, this.chouJiang.time)
                    }

                })
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/.van-empty{
        padding: 0px;
    }
    .page {
        width: 100%;
        height: 100%;
        background-image: url("../../../static/images/fifthBack.png");
        background-size: cover;

        .page_head {
            padding: 48px 20px 0 20px;

            img {
                width: 100%;
                height: auto;
            }
        }

        .page_center {
            position: relative;
            bottom: 25px;
            padding: 0 15px;
            width: 100%;
            min-height: 288px;

            .center_eag {
                width: 100%;
                height: auto;
            }

            .center_hammer {
                position: absolute;
                top: 30%;
                right: 45px;
                width: 99px;
                height: 116px;
            }

            .center_btn {
                width: 110px;
                height: 38px;
                position: absolute;
                bottom: 5%;
                left: 48%;
                transform: translateX(-50%);
            }
        }

        .page_info {
            margin: 0px 22px 30px 22px;
            padding: 20px 0;
            background: #FFFFFF;
            opacity: 0.77;
            border-radius: 12px;

            .info_title {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                font-weight: 500;
                color: #C90605;

                span {
                    width: 18px;
                    height: 2px;
                    background-color: #C90605;
                    /* border-top: 1px solid #C90605; */
                }
            }

            .info_list {
                margin-top: 10px;
                padding: 0 45px;

                .list_box {
                    display: flex;
                    justify-content: space-around;
                }
            }

            .recordBox {
                text-align: center;
                color: #C90605;
                font-size: 12px;
            }
        }

        .record_title {
            display: flex;
            justify-content: center;

            img {
                width: 225px;
                height: 27px;
            }
        }

        .record_box {
            background: #F24748;
            border-radius: 12px;
            margin: 15px;
            padding: 8px;


            .record_mar {
                padding: 15px 22px;
                background: #FFFFFF;
                border-radius: 12px;
                height: 180px;
                overflow: hidden;
                overflow-y: scroll;
                .record_list {

                    .box_title {
                        font-size: 12px;
                        font-weight: 400;
                        color: #DC1912;
                    }

                    .box_info {
                        margin-top: 5px;
                        padding: 10px 12px;
                        background: #FFE5D9;
                        border-radius: 5px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .info_left {
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            img {
                                display: flex;
                                flex-direction: row;
                                width: 45px;
                                height: 45px;
                            }

                            span {
                                font-size: 16px;
                                font-weight: 400;
                                color: #DC1912;
                                margin-left: 15px;
                            }
                        }

                        .info_right {
                            display: flex;

                            padding: 0 20px;
                            align-items: center;
                            height: 28px;
                            background: linear-gradient(0deg, #FEDA62, #FFE06A);
                            border-radius: 14px;
                            font-size: 16px;
                            font-weight: 500;
                            color: #EF2823;
                        }
                    }
                }

            }
        }
    }
    .pop_up{
        width: 100%;
        background-color: #00000094;
        position: fixed;
        top: 110%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    @media screen and (max-width:370px) {
        .page .page_info .info_list {
            padding: 0 15px
        }
    }
</style>