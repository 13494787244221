<template>
    <div class="page">
        <a-spin :spinning="isLoading" tip="加载中...">
            <div class="page_head">
                <img src="../../../static/images/sixthHead.png" alt="">
            </div>
            <div class="cen">
                <!-- <div class="box"> -->
                <LuckyWheel style="width: 300px; height: 300px" ref="LuckyWheel" :prizes="luckDraw.prizes"
                    :defaultConfig="luckDraw.defaultConfig" :blocks="luckDraw.blocks" :buttons="luckDraw.buttons"
                    @start="startCallBack" />
                <!-- </div> -->
            </div>
            <div class="info">
                <div class="notice">
                    <van-notice-bar left-icon="volume-o" color="#BF0A0B"
                        v-if="clientData.message&&clientData.message.length!=0"
                        :text="`恭喜${clientData.message.nickname}获得${clientData.message.prize_name}`" />
                    <van-notice-bar left-icon="volume-o" color="#BF0A0B" v-else text="暂无中奖记录" />
                </div>
            </div>
            <div class="record_title">
                <img src="../../../static/images/fifthRecond.png" alt="">
            </div>
            <div class="record_box">
                <div class="record_mar">
                    <div v-if="clientData.win_list&&clientData.win_list.lenth!=0">
                        <div v-for="(item,index) of clientData.win_list" :key="index">
                            <div class="record_list">
                                <div class="box_title">{{item.createdAt}}</div>
                                <div class="box_info">
                                    <div class="info_left">
                                        <img :src="item.prizeImage || item.receiveQr" alt="">
                                        <span>{{ item.prizeName }}</span>
                                    </div>
                                    <div v-if="item.receiveType == 3 ||item.receiveType == 4||item.receiveType == 6">
                                        <div class="info_right" v-if="item.receiveStatus == 0" @click="skip(item)">兑换
                                        </div>
                                        <div class="info_right" style="background: #C0C0C0;color: #ffffff;" v-else>已兑换</div>
                                    </div>
                                    <div v-else>
                                        <div class="info_right" v-if="item.receiveStatus == 0"
                                            @click="receiveReward(item)">兑换</div>
                                        <div class="info_right" style="background: #C0C0C0;color: #ffffff;" v-else @click="receiveReward(item)">已兑换</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <van-empty v-else description="暂无记录" />
                </div>
            </div>
            <div style="width: 100%;height: 40px;"></div>
            <div class="pop_up">
                <prize ref="prize" @childEvent="getClientData"/>
            </div>
        </a-spin>
    </div>
</template>

<script>
    import { contactLotteryApi } from "@/api/lottery";
    import { publicMixin } from "@/mixin/index.js"
    export default {
        mixins: [publicMixin],
        data() {
            return {
                isLoading: false,
                list: [{}, {}],
                //  抽奖
                luckDraw: {
                    //轮盘数据
                    prizes: [],
                    defaultConfig: {
                        //默认样式
                        gutter: "0px", //扇形之间的缝隙（默认等于 0无间隙）
                        offsetDegree: 45,
                    },
                    blocks: [
                        //背景样式修改
                        {
                            padding: "17px",
                            imgs: [
                                {
                                    src: require("../../../static/images/sixthDarw.png"), // 转盘底图
                                    width: "100%",
                                    rotate: true,
                                },
                            ],
                        },
                        { padding: "0px", background: "#F9D201" },
                    ],
                    buttons: [
                        { radius: "28px", background: "#FF8171", pointer: true },
                        { radius: "35px", background: "#FF8171" },
                        {
                            radius: "30px",
                            background: "#FFDF56",
                            fonts: [
                                {
                                    text: "开始\n抽奖",
                                    fontSize: "19px",
                                    top: -20,
                                    fontColor: "#FF4949",
                                },
                            ],
                        },
                    ],
                },
                clientData: {}, //  客户数据
                prizeSetNews: [],//  设置的奖品
                // source: 'from_pc'
            }
        },
        created() {
            //  获取参数
            this.id = this.$route.query.id;
            // this.source = this.$route.query.source;
            this.employee_id = this.$route.query.employee_id;
            this.getClientData()
            this.handSetprize(this.prizeData)
        },
        mounted() {

        },
        methods: {
            //处理奖品设置数据
            handSetprize(data) {
                this.luckDraw.prizes = [];
                data.forEach((item, index) => {
                    let prizeNews = {
                        name: "",
                        background: index % 2 == 0 ? "#FFEF9D" : "#FFFFFF",
                        fonts: [
                            {
                                text: "",
                                fontSize: "16px",
                                top: 5,
                                fontColor: '#DC1912'
                            },
                        ],
                        imgs: [
                            {
                                src: "", // 转盘底图
                                width: '60px',
                                height: '60px',
                                top: '25px',
                                rotate: true,
                            },
                        ],
                    };
                    prizeNews.name = item.name;
                    prizeNews.fonts[0].text = item.name;
                    prizeNews.imgs[0].src = item.image;
                    this.luckDraw.prizes.push(prizeNews);
                });
            },
            startCallBack(){
                this.debounce(this.startCallbackNext, 3000);
            },
            //  开始抽奖
            startCallbackNext() {
                contactLotteryApi({
                    id: this.id,
                    union_id: this.weChatUserNews.unionid,
                    nickname: this.weChatUserNews.nickname,
                }).then((res) => {
                    let prizeInfo = {};
                    //在哪里停止
                    this.prizeSetNews.forEach((item, index) => {
                        if (item.name == res.data.prize_name) {
                            prizeInfo = item;
                            this.$refs.LuckyWheel.play();
                            setTimeout(() => {
                                // 索引值
                                this.$refs.LuckyWheel.stop(index); // index 索引代表抽中奖品数组中第几个奖品
                            }, 3000);
                        }
                    });
                    //弹窗提醒
                    setTimeout(() => {
                        let prizeData = {
                            openId: this.openId,
                            receiveType: res.data.receive_type,
                            prizeName: res.data.prize_name,
                            receiveCode: res.data.receive_code,
                            receiveQr: res.data.receive_qr,
                            description: res.data.description,
                            record_id: res.data.record_id,
                            nickname: this.weChatUserNews.nickname,
                            headimgurl: this.weChatUserNews.headimgurl,
                            money:res.data.money,
                        };
                        // if (prizeInfo.type == 2) {
                            prizeData.receiveQr = prizeInfo.image;
                        // }
                        this.$refs.prize.show(prizeData);
                        this.getClientData();
                    }, 6000);
                });
            },
        }
    }
</script>

<style lang="less" scoped>
    /deep/.van-empty{
        padding: 0px;
    }
    .page {
        width: 100%;
        height: 100%;
        background-image: url("../../../static/images/sixth.png");
        background-size: cover;

        .page_head {
            padding: 70px 20px 0 20px;

            img {
                width: 100%;
                height: auto;
            }
        }

        .cen {
            width: 100%;
            height: 343px;
            display: flex;
            justify-content: center;
            margin-top: 30px;

        }

        .info {
            padding: 0 35px;

            .notice {
                width: 100%;
                height: 30px;
                border-radius: 30px;

                /* background: #FFFFDA;
              box-shadow: 2px 2px 2px 2px #E72926;  */
            }
        }

        .record_title {
            display: flex;
            justify-content: center;
            margin-top: 33px;

            img {
                width: 225px;
                height: 27px;
            }
        }

        .record_box {
            background: #FEBE8D;
            border-radius: 12px;
            margin: 15px;
            padding: 8px;
            margin-bottom: 30px;

            .record_mar {
                padding: 15px 22px;
                background: #FFFFFF;
                border-radius: 12px;
                height: 233px;
                overflow-y: scroll;
                .record_list {

                    .box_title {
                        font-size: 12px;
                        font-weight: 400;
                        color: #DC1912;
                    }

                    .box_info {
                        margin-top: 5px;
                        padding: 10px 12px;
                        background: #FFE5D9;
                        border-radius: 5px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .info_left {
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            img {
                                display: flex;
                                flex-direction: row;
                                width: 45px;
                                height: 45px;
                            }

                            span {
                                font-size: 16px;
                                font-weight: 400;
                                color: #DC1912;
                                margin-left: 15px;
                            }
                        }

                        .info_right {
                            display: flex;

                            padding: 0 20px;
                            align-items: center;
                            height: 28px;
                            background: linear-gradient(0deg, #FEDA62, #FFE06A);
                            border-radius: 14px;
                            font-size: 16px;
                            font-weight: 500;
                            color: #EF2823;
                        }
                    }
                }

            }
        }
    }
    .pop_up{
        width: 100%;
        background-color: #00000094;
        position: fixed;
        top: 110%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    /deep/ .van-notice-bar {
        height: 30px;
        padding: 0px 10px !important;
        background-color: #FFFFDA !important;
        line-height: 30px;
        border-radius: 30px;
    }
</style>