<template>
    <div class="page">
        <a-spin :spinning="isLoading" tip="加载中...">
            <div class="head">
                <img src="../../../static/images/thirdHead.png" alt="">
            </div>
            <div class="cen">
                <LuckyGrid ref="LuckyDraw" width="365px" height="365px" :prizes="prizes" :blocks="blocks"
                    :buttons="buttons" @start="startCallback" @end="endCallback" :defaultStyle="defaultStyle"
                    :activeStyle="activeStyle" />
            </div>
            <div class="page_info">
                <div class="info_title">
                    <span style="margin-right: 5px;"></span>
                    中奖名单
                    <span style="margin-left: 5px;"></span>
                </div>
                <div class="info_list">
                    <div v-if="clientData.message&&clientData.message.length!=0">
                        <div class="list_box">
                            <span>{{ clientData.message.nickname }}</span>
                            <span>{{ clientData.message.prize_name }}</span>
                        </div>
                    </div>
                    <div class="recordBox" v-else>暂无中奖记录</div>
                </div>
            </div>
            <div class="record_title">
                <img src="../../../static/images/fifthRecond.png" alt="">
            </div>
            <div class="record_box">
                <div class="record_mar">
                    <div v-if="clientData.win_list&&clientData.win_list.length!=0">
                        <div v-for="(item,index) of clientData.win_list" :key="index">
                            <div class="record_list">
                                <div class="box_title">{{item.createdAt}}</div>
                                <div class="box_info">
                                    <div class="info_left">
                                        <img :src="item.prizeImage || item.receiveQr" alt="">
                                        <span>{{ item.prizeName }}</span>
                                    </div>
                                    <div v-if="item.receiveType == 3 ||item.receiveType == 4||item.receiveType == 6">
                                        <div class="info_right" v-if="item.receiveStatus == 0" @click="skip(item)">兑换
                                        </div>
                                        <div class="info_right" style="background: #C0C0C0;color: #ffffff;" v-else>已兑换</div>
                                    </div>
                                    <div v-else>
                                        <div class="info_right" v-if="item.receiveStatus == 0"
                                            @click="receiveReward(item)">兑换</div>
                                        <div class="info_right" style="background: #C0C0C0;;color: #ffffff;" v-else @click="receiveReward(item)">已兑换</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <van-empty v-else description="暂无记录" />
                </div>
            </div>
            <div style="width: 100%;height: 40px;"></div>
            <div class="pop_up">
                <prize ref="prize" @childEvent="getClientData"/>
            </div>
        </a-spin>
    </div>
</template>

<script>
    import { contactLotteryApi } from "@/api/lottery";
    import { publicMixin } from "@/mixin/index.js"
    export default {
        mixins: [publicMixin],
        data() {
            return {
                isLoading: false,
                list: [],
                //背景区域
                // blocks: [
                //     { padding: '10px', background: '#FD4A30', },
                //     { padding: '10px', background: '#C93C1F', },
                // ],
                blocks: [{
                    padding: '35px',
                    imgs: [{
                        src: require("../../../static/images/thirdDraw.png"),
                        width: '100%',
                        height: '100%'
                    }]
                }],
                prizes: [],
                buttons: [
                    {
                        x: 1, y: 1,
                        background: 'rgba(0,0,0,0)',
                        imgs: [{
                            src: require("../../../static/images/thirdBtn.png"),
                            width: '100%',
                            height: '100%',
                        }],
                    },
                ],
                defaultStyle: {
                    background: '#FDF2EE'
                },
                activeStyle: {
                    background: '#FFD220'
                },
                clientData: {}, //  客户数据
                prizeSetNews: [],//  设置的奖品
                // source: 'from_pc',
                drawData: {},//中奖数据
            }
        },
        created() {
            this.id = this.$route.query.id;
            this.getClientData()
            this.prizeSetNews = this.prizeData
            this.handlePrize()
        },
        methods: {
            //处理抽奖数据
            handlePrize() {
                let prizes = [
                    { x: 0, y: 0 },
                    { x: 1, y: 0 },
                    { x: 2, y: 0 },
                    { x: 2, y: 1 },
                    { x: 2, y: 2 },
                    { x: 1, y: 2 },
                    { x: 0, y: 2 },
                    { x: 0, y: 1 },
                ]
                let prizeLength = this.prizeSetNews.length;
                let newIndex = 0;
                prizes.forEach((item, index) => {
                    //数据不够9个，重复奖品
                    if (newIndex === prizeLength) {
                        newIndex = 0
                        item.fonts = [{ text: this.prizeSetNews[newIndex].name, top: '70%', fontColor: '#C93C1F', fontSize: '11px' }]
                        item.imgs = [{
                            src: this.prizeSetNews[newIndex].image,
                            width: '40px',
                            height: '40px',
                            top: '18px'
                        }]
                    } else {
                        item.fonts = [{ text: this.prizeSetNews[newIndex].name, top: '70%', fontColor: '#C93C1F', fontSize: '11px' }]
                        item.imgs = [{
                            src: this.prizeSetNews[newIndex].image,
                            width: '40px',
                            height: '40px',
                            top: '18px'
                        }]
                    }
                    newIndex++
                })
                this.prizes = prizes;
            },
            startCallBack(){
                this.debounce(this.startCallbackNext, 3000);
            },
            //开始抽奖
            startCallbackNext() {
                //调取接口获取中奖数据
                contactLotteryApi({
                    id: this.id,
                    union_id: this.weChatUserNews.unionid,
                    nickname: this.weChatUserNews.nickname,
                }).then((res) => {
                    if (res.code == 200) {
                        this.$refs.LuckyDraw.play()
                        this.drawData = res.data;
                        //根据接口查询中奖商品详情
                        this.prizes.filter((item, index) => {
                            if (item.fonts[0].text === res.data.prize_name) {
                                this.$refs.LuckyDraw.stop(index)
                            }
                        })
                    }
                })
            },
            endCallback(prize) { // 游戏结束触发 end 回调
                let prizeInfo = {};
                // alert('恭喜中奖: ' + prize.fonts[0].text)
                this.prizeSetNews.forEach((item, index) => {
                    if (item.name == this.drawData.prize_name) {
                        prizeInfo = item;
                    }
                });
                let prizeData = {
                    openId: this.openId,
                    receiveType: this.drawData.receive_type,
                    prizeName: this.drawData.prize_name,
                    receiveCode: this.drawData.receive_code,
                    receiveQr: this.drawData.receive_qr,
                    description: this.drawData.description,
                    record_id: this.drawData.record_id,
                    nickname: this.weChatUserNews.nickname,
                    headimgurl: this.weChatUserNews.headimgurl,
                    money:res.data.money,
                };
                // if (prizeInfo.type == 2) {
                prizeData.receiveQr = prizeInfo.image;
                // }
                this.$refs.prize.show(prizeData);
                this.getClientData();
            }
        }
    }
</script>

<style lang="less" scoped>
    /deep/.van-empty{
        padding: 0px;
    }
    .page {
        width: 100%;
        height: 100%;
        background-image: url("../../../static/images/thirdBack.png");
        background-size: cover;

        .head {
            width: 100%;
            height: 266px;

            img {
                width: 100%;
                height: 266px;
            }
        }

        .cen {
            width: 100%;
            /* height: 500px; */
            margin: 22px 0 40px 0;
            display: flex;
            justify-content: center;
        }

        .page_info {
            margin: 0px 22px 30px 22px;
            padding: 20px 0;
            background: #FFFFFF;
            opacity: 0.77;
            border-radius: 12px;

            .info_title {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                font-weight: 500;
                color: #C90605;

                span {
                    width: 18px;
                    height: 2px;
                    background-color: #C90605;
                    /* border-top: 1px solid #C90605; */
                }
            }

            .info_list {
                margin-top: 10px;
                padding: 0 45px;

                .list_box {
                    display: flex;
                    justify-content: space-around;
                }

                .recordBox {
                    text-align: center;
                    color: #C90605;
                    font-size: 12px;
                }
            }
        }

        .record_title {
            display: flex;
            justify-content: center;

            img {
                width: 225px;
                height: 27px;
            }
        }

        .record_box {
            background: #F24748;
            border-radius: 12px;
            margin: 15px;
            padding: 8px;

            .record_mar {
                padding: 15px 22px;
                background: #FFFFFF;
                border-radius: 12px;
                height: 233px;
                overflow-y: scroll;

                .record_list {

                    .box_title {
                        font-size: 12px;
                        font-weight: 400;
                        color: #DC1912;
                    }

                    .box_info {
                        margin-top: 5px;
                        padding: 10px 12px;
                        background: #FFE5D9;
                        border-radius: 5px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .info_left {
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            img {
                                display: flex;
                                flex-direction: row;
                                width: 45px;
                                height: 45px;
                            }

                            span {
                                font-size: 16px;
                                font-weight: 400;
                                color: #DC1912;
                                margin-left: 15px;
                            }
                        }

                        .info_right {
                            display: flex;

                            padding: 0 20px;
                            align-items: center;
                            height: 28px;
                            background: linear-gradient(0deg, #FEDA62, #FFE06A);
                            border-radius: 14px;
                            font-size: 16px;
                            font-weight: 500;
                            color: #EF2823;
                        }
                    }
                }

            }
        }
    }

    .pop_up {
        width: 100%;
        background-color: #00000094;
        position: fixed;
        top: 110%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media screen and (max-width:370px) {
        .page .page_info .info_list {
            padding: 0 15px
        }
    }
</style>