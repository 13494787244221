import {request, request_op} from "../plugins/axios";

export function contactDataApi (params) {
    return request({
        url: '/lottery/contactData',
        method: 'post',
        params: params
    })
}

export function contactLotteryApi (params) {
    return request({
        url: '/lottery/contactLottery',
        method: 'put',
        data: params
    })
}

export function receiveApi (params) {
    return request({
        url: '/lottery/receive',
        method: 'put',
        data: params
    })
}
//领取代金券
export function receivecouponApi (params) {
    return request_op({
        url: '/openUserInfo/receivecoupon',
        method: 'post',
        data: params
    })
}
//获取授权url
export function getUrlApi (params) {
    return request_op({
        url: '/openUserInfo/getauthurl',
        method: 'get',
        params: params
    })
}
//获取二维码
export function getQrcodeApi (params) {
    return request({
        url: '/lottery/getqrcode',
        method: 'get',
        params: params
    })
}
//获取微信用户信息
export function openUserInfoApi (params) {
    return request_op({
        url: '/openUserInfo/lottery',
        method: 'GET',
        params: params
    })
}