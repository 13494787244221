<template>
  <div class="record" v-show="prizeShow">
    <div class="bg" @click="prizeShow = false"></div>
    <div class="box1" v-if="type == 3 || type == 4">
      <div class="end" @click="hide">×</div>
      <p>— {{ receiveName }} —</p>
      <img src="./btn.png" alt="" @click="receive()" />
    </div>
    <!-- 现场核销弹窗 -->
    <div class="popBox" v-else-if="type == 5">
      <div class="boxQc" ref="qrcode"></div>
      <div class="tips">
        恭喜您中奖【{{receiveName}}】，将二维码发送给小助理领取奖品！可以先把页面截图保存哦！
      </div>
      <div class="closeBox" @click="hide">
        <img src="../../static/images/opoClose.png" alt="">
      </div>
    </div>
    <!-- 现金红包弹窗 -->
    <div class="cashBox" v-else-if="type == 6">
      <div class="titleTips">
        <span v-show='!cashStatus'>中奖啦</span>
      </div>
      <div class="textTips" v-show='!cashStatus'>
        <div class="line-left"></div>
        <div class="line-center">恭喜你获得</div>
        <div class="line-right"></div>
      </div>
      <div class="amoutBox" :class="cashStatus?'amoutMar':''">
        <span class="amout">{{money}}</span>
        <span class="tips">元</span>
      </div>
      <div class="cashTips" v-show='cashStatus'>
        已存入微信钱包，请到钱包查看！
      </div>
      <div class="Btn" @click="cashReceive()" v-show='!cashStatus'>领取</div>
      <div class="Btn" @click="cashHide()" v-show='cashStatus'>好的</div>
      <div class="closeBox" @click="cashHide()">
        <img src="../../static/images/opoClose.png" alt="">
      </div>
    </div>
    <div class="lottery-box" v-else>
      <div class="end" @click="hide">×</div>
      <div class="top">
        <img :src="headimgurl" alt="" v-show="headimgurl" />
        <p>{{ nickname }}</p>
      </div>

      <div class="content">
        <div class="prize_no" v-if="controlStyle" style="margin: 15px 0 40px">
          {{ receiveName }}
        </div>
        <div class="box" v-else>
          <div v-if="redeemCode == ''" class="qc-code">
            <img :src="qrCode" height="100" width="100" />
          </div>
          <div class="exchange_code">
            <div style="color: #e3453a; margin-right: 5px;text-align: center;">{{
              receiveName
              }}</div>
            <div v-if="redeemCode != ''">
              兑换码：<span>{{ redeemCode }}</span>
            </div>
            <div style="margin-bottom: 25px">备注:{{ defaultData }}~</div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { getUrlApi, receivecouponApi } from "@/api/lottery";
  import QRCode from 'qrcodejs2'
  export default {
    data() {
      return {
        prizeShow: false,
        qrCode: "",
        redeemCode: "",
        receiveName: "",
        headimgurl: "",
        nickname: "",
        controlStyle: false,
        defaultData: "",
        type: "",
        id: '',
        money: '',
        cashStatus: false

      };
    },
    methods: {
      skip() {
        this.$router.push({ path: "/tips" });
      },
      receive(type) {
        let path = '/tips?id=' + this.id;
        getUrlApi({ id: this.id, path: path }).then(res => {
          console.log('获取代金卷id', res)
          console.log('url', res.data.auth_url)
          if (res.code == 200) {
            location.href = res.data.auth_url
          }
        })

      },
      cashReceive() {
        //跳转刷新逻辑
        //获取当前的参数拼接
        // if (this.$route.query.openid) {//存在openid
        //   receivecouponApi({ id: this.id, openid: this.$route.query.openid }).then((res) => {
        //     if (res.code == 200) {
        //       this.cashStatus = true
        //     }
        //   });
        // }else{
        //    localStorage.removeItem('openid');//清理缓存
        //    localStorage.removeItem('openidExpireTime');
        //   let newPath = '/lottery?id=' + this.$route.query.id + '&source=' + this.$route.query.source + '&prizeId=' + this.id;
        //   if (this.$route.query.employee_id) {
        //     newPath += '&employee_id=' + this.$route.query.employee_id;
        //   }
        //   getUrlApi({ id: this.id, path: newPath }).then(res => {
        //     if (res.code == 200) {
        //       location.href = res.data.auth_url
        //     }
        //   })
        //   }

        getUrlApi({ id: this.id, path: '/cashTips?id=' + this.id }).then(res => {
          console.log('获取代金卷id', res)
          console.log('url', res.data.auth_url)
          if (res.code == 200) {
            location.href = res.data.auth_url
          }
        })


      },
      show(data, cashStatus) {
        this.cashStatus = cashStatus ? true : false
        this.$refs.qrcode ? this.$refs.qrcode.innerHTML = "" : ''
        this.id = data.record_id ? data.record_id : data.id
        this.type = data.receiveType;
        this.defaultData = data.description;
        this.prizeShow = true;
        this.receiveName = data.prizeName;
        this.qrCode = data.receiveQr;
        this.redeemCode = data.receiveCode;
        this.nickname = data.nickname;
        this.headimgurl = data.headimgurl;
        this.money = data.money;
        if (data.receiveType == 5) {//现场核销，绘制二维码
          setTimeout(() => {
            this.setQrcode()
          }, 500);
        }
        if (this.receiveName == "谢谢参与") {
          this.controlStyle = true;
        } else {
          this.controlStyle = false;
        }
      },
      hide() {
        this.prizeShow = false;
      },
      cashHide(){
        this.prizeShow = false;
        this.$emit('childEvent')
      },
      //绘制二维码
      setQrcode() {
        new QRCode(this.$refs.qrcode, {
          text: this.redeemCode,
          width: 140,
          height: 140,
          // colorDark:'#333',//二维码颜色
          colorLight: '#fff',//二维码背景颜色
          correctLevel: QRCode.CorrectLevel.L//容错率,L/M/H
        })
      }
    },
  };
</script>

<style scoped lang="less">
  .box1 {
    width: 84vw;
    ;
    height: 264px;
    background-color: #ffffff;
    border-radius: 28px;
    margin-top: -1020px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 20;
    background-image: url("./popu.png");
    background-size: 84vw 266px;

    img {
      width: 50vw;
      height: 40px;
    }

    p {
      margin: 0 auto;
      margin-top: 0.58667rem;
      color: #702a23;
      font-size: 16px;
      min-width: 50vw;
      text-align: center;
    }
  }

  .record {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;

    .bg {
      width: 100vw;
      height: 100vh;
      background-color: #00000094;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
    }

    .popBox {
      width: 100%;
      height: 318px;
      padding: 0 50px;
      background-image: url("../../static/images/popshortBack.png");
      background-size: 100% 100%;
      position: relative;
      z-index: 20;
      margin-top: -1020px;

      .title {
        font-size: 22px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 31px;
        text-shadow: 0px 1px 1px #CC2800;
        text-align: center;
        margin-top: 8px;
      }

      .boxTime {
        margin-top: 45px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: #FF562D;
        line-height: 21px;
      }

      .boxQc {
        margin: 0 auto;
        margin-top: 50px;
        background: #FFFFFF;
        padding: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 150px;
      }

      .tips {
        margin-top: 25px;
        font-size: 12px;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
        text-align: center;
      }

      .closeBox {
        position: absolute;
        z-index: 33;
        bottom: -35px;
        left: 50%;
        transform: translateX(-50%);

        img {
          width: 32px;
          height: 32px;
        }
      }
    }

    .lottery-box {
      width: 88vw;
      min-height: 100px;
      background-color: #ffffff;
      border-radius: 18px;
      margin-top: -1020px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      position: relative;
      z-index: 20;
      font-size: 18px;

      .top {
        width: 78vw;
        display: flex;
        align-items: center;
        height: 30px;
        margin-top: 32px;

        img {
          width: 33px;
          height: 33px;
        }

        p {
          color: #e3453a;
          margin-left: 14px;
          font-size: 18px;
          font-weight: bold;
          margin-top: 17px;
        }

        span {
          display: block;
          margin-top: -16px;
        }
      }

      .box {
        width: 78vw;
        min-height: 100px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .qc-code {
          width: 70vw;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 120px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .end {
    position: absolute;
    font-size: 24px;
    right: 12px;
  }

  .exchange_code {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;

    span {
      font-weight: bold;
      font-size: 15px;
    }
  }

  .prize_no {
    font-weight: bold;
    font-size: 21px;
    margin-top: -45px;
  }

  .cashBox {
    width: 340px;
    height: 353px;
    // padding: 0 50px;
    background-image: url("../../static/images/cashNewback.png");
    background-size: 100% 100%;
    position: relative;
    z-index: 20;
    margin-top: -1020px;

    .titleTips {

      width: 100%;
      text-align: center;
      margin-top: 30px;

      span {
        font-size: 31px;
        font-weight: 400;
        color: #C02C00;
      }
    }

    .textTips {
      width: 100%;
      display: flex;
      flex-direction: row;
      height: 30px;
      align-items: center;
      justify-content: center;
      line-height: 30px;
      margin-top: 10px;

      .line-left {
        width: 50px;
        height: 1px;
        background: linear-gradient(90deg, #FFFFFF 0%, #C02C00 100%);
        margin-right: 10px;
      }

      .line-center {
        font-size: 15px;
        font-weight: 400;
        color: #C02C00;
      }

      .line-right {
        width: 50px;
        height: 1px;
        background: linear-gradient(90deg, #C02C00 0%, #FFFFFF 100%);
        margin-left: 10px;
      }
    }

    .cashTips {
      width: 100%;
      text-align: center;
      margin-top: 30px;
      font-size: 14px;
      font-weight: 400;
      color: #6C6C6C;
    }

    .amoutBox {
      width: 100%;
      text-align: center;
      margin-top: 10px;

      .amout {
        font-size: 52px;
        font-weight: 600;
        color: #C02C00;
      }

      .tips {
        font-size: 21px;
        font-weight: 600;
        color: #C02C00;
      }
    }

    .amoutMar {
      margin-top: 45px;
    }

    .Btn {
      font-size: 26px;
      font-weight: 500;
      color: #F8D7AE;
      position: absolute;
      z-index: 33;
      bottom: 35px;
      left: 50%;
      transform: translateX(-50%);
      background-image: url("../../static/images/cashBtn.png");
      background-size: 100% 100%;
      width: 252px;
      height: 54px;
      font-size: 20px;
      line-height: 54px;
      font-weight: 500;
      color: #C02C00;
      text-align: center;
    }

    .closeBox {
      position: absolute;
      z-index: 33;
      bottom: -50px;
      left: 50%;
      transform: translateX(-50%);

      img {
        width: 32px;
        height: 32px;
      }
    }
  }
</style>