<template>
<div class="fath">
   <a-spin :spinning="spinning" tip="领取中...">
  <div class="tips">
    <p>领取成功</p>
    <p>您可以前往微信【我】-【卡包】</p>
    <p>中查看代金劵信息。</p>
  </div>
  </a-spin>
  </div>
</template>
<script>
import {receivecouponApi} from '@/api/workFission'
export default {
  data() {
    return {
        couponOpenid:'',
        id:'',
        spinning:false
    };
  },
  created(){
    this.getData()
  },
  methods: {
    getData(){
      this.spinning=true
      this.id=location.search.split('&')[0].split('=')[1]
      this.couponOpenid=location.search.split('&')[1].split('=')[1]
        console.log('loction.search',location.search)
        console.log('this.couponopenid',this.couponOpenid)
        receivecouponApi({id:this.id,openid:this.couponOpenid}).then((res) => {
        console.log("res", res);
        if(res.code==200){
          this.spinning=false
        }
      });
    }
  },
};
</script>

<style scoped lang="less">
.fath{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 200px;
}
.tip {
  width: 230px;
  height: 63px;
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #515151;
  line-height: 21px;
}
p {
  margin: 0;
  text-align: center;
  font-size: 15px;
}
</style>