<template>
  <a-spin :spinning="isLoading" tip="加载中...">
    <tempSecond v-if="defaultPage=='roulette'" :prizeData="prizeSetNews" :weChatUserNews="weChatUserNews"/>
    <tempFirst v-if="defaultPage==4" :prizeData="prizeSetNews" :weChatUserNews="weChatUserNews" />
    <tempFifth v-if="defaultPage==6" :prizeData="prizeSetNews" :weChatUserNews="weChatUserNews"/>
    <tempFourth v-if="defaultPage==5" :prizeData="prizeSetNews" :weChatUserNews="weChatUserNews" />
    <tempThird v-if="defaultPage==3" :prizeData="prizeSetNews" :weChatUserNews="weChatUserNews"/>
    <tempSixth v-if="defaultPage==7" :prizeData="prizeSetNews" :weChatUserNews="weChatUserNews"/>
    <tempSeventh v-if="defaultPage==2" :prizeData="prizeSetNews" :weChatUserNews="weChatUserNews"/>
    <van-popup v-model="showTips" class="popupBox1">
      <img style=" width: 100px; height: 100px;" src="./1.gif" alt="" />
    </van-popup>
    <van-popup v-model="showCode" closeable @close="reload" class="popupBox2">
      <img class="popupBox2Img" :src="qrCode" alt="" />
      <div class="m16">您还不是久鸿客户，暂无抽奖权限</div>
      <div class="m17">长按二维码添加久鸿客户经理</div>
    </van-popup>
  </a-spin>
</template>

<script>
  import tempFirst from './components/pageFirst.vue'  //老虎机活动
  import tempSecond from './components/pageSecond.vue'  //大转盘
  import tempFifth from './components/pageFifth.vue' //砸金蛋活动
  import tempFourth from './components/pageFourth.vue' //扭一扭活动
  import tempThird from './components/pageThird.vue'  //九宫格活动
  import tempSixth from './components/pageSixth.vue'  //大转盘新
  import tempSeventh from './components/pageSeventh.vue'  //翻牌
  import { wxConfig } from "../../utils/wxCodeAuth";
  import {
    contactDataApi,
    openUserInfoApi,
    contactLotteryApi,
    receiveApi,
    getQrcodeApi,
  } from "@/api/lottery";
  import { Popup } from "vant";
  export default {
    components: {
      tempFirst,
      tempSecond,
      tempFifth,
      tempFourth,
      tempThird,
      tempSixth,
      tempSeventh,
      wxConfig,
      Popup
    },
    data() {
      return {
        isLoading: false,
        defaultPage: 0,
        showTips: false,
        employee_id: "",
        showCode: false,
        qrCode: "",
        url: window.location.href,
        weChatUserNews: {
          // city: "",
          // country: "",
          // headimgurl: "https://thirdwx.qlogo.cn/mmopen/vi_32/DPKgeX7hrGibzFFrdIqiaYTiaqYibJCibjkXEylgjD92dFbQia8hK5qPce1FH8LctoF4ErOmtAgOFzv7MJsJpmOWL1Rg/132",
          // language: "",
          // nickname: "韧真",
          // openid: "oWx9w6Gcpp-Hft-K_bqLQl9XtRfI",
          // privilege: [],
          // province: "",
          // sex: 0,
          // unionid: "o7W6Z5uUfEJ81Adevo_-5xlFL1eU"
        }, //  用户微信信息
        clientData: {}, //  客户数据
        corp: {},
        prizeSetNews: [],//  设置的奖品
        shareOption: {},
        openId:'',
        source:'',
        // openId: "oWx9w6NbzSXxp6z9Rjy5eL057hBw",
        // source: 'from_pc'
      };
    },
    created() {
      //  获取参数
      this.id = this.$route.query.id;
      this.source = this.$route.query.source;
      this.employee_id = this.$route.query.employee_id;
      this.getOpenUserInfo();
    },
    methods: {
      goPage(type) {
        this.defaultPage = type
      },
      //获取用户信息
      getOpenUserInfo() {
        let that = this;
        openUserInfoApi({
          id: that.id,
        }).then((res) => {
          //如果不存在openid 则跳转授权
          if (res.data.openid === undefined) {
            let redirectUrl =
              location.protocol +
              "//" +
              location.host +
              "/auth/lottery?id=" +
              that.id +
              "&target=" +
              encodeURIComponent("/lottery" + location.search);
            window.location.href = redirectUrl;
            return;
          }
          this.openId = res.data.openid;
          this.weChatUserNews = res.data;
          this.getClientData();
        });
      },
      //  获取抽奖数据
      getClientData() {
        this.isLoading = true;
        let params = {
          id: this.id,
          union_id: this.weChatUserNews.unionid,
          nickname: this.weChatUserNews.nickname,
          avatar: this.weChatUserNews.headimgurl,
          city: this.weChatUserNews.city,
          source: this.source,
        };
        contactDataApi(params).then((res) => {
          if (this.weChatUserNews.nickname == "微信用户") {
            this.isLoading = false;
            this.showTips = true;
            return;
          }

          document.title = res.data.lottery.name;
          this.clientData = res.data;

          this.isLoading = false;
          //判断是否属于我行客户
          if (
            this.clientData.point.is_employee_contact == 0 &&
            this.clientData.point.is_contact == 1
          ) {
            getQrcodeApi({ id: this.id, employee_id: this.employee_id }).then(
              (res) => {
                console.log("__", res);
                this.qrCode = res.data.qr_code;
                this.showCode = true;
              }
            );
            return;
          }
          res.data.prize.prizeSet.forEach((item, index) => {
            this.clientData.win_list.forEach((obj, idx) => {
              if (item.name == obj.prizeName) {
                if (item.type == 2) {
                  obj.receiveQr = item.image;
                }
              }
            });
          });
          this.corp = res.data.corp;
          //  处理奖品设置数据
          this.prizeSetNews = this.clientData.prize.prizeSet;
          this.defaultPage = res.data.lottery.type;
          // this.handSetprize(this.prizeSetNews);

          //分享内容
          this.shareOption = {
            title: res.data.lottery.name, // 分享标题
            desc: res.data.lottery.description, // 分享描述
            link: this.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
            imgUrl: res.data.lottery.cover, // 分享图标
            success: function () {
              // 用户点击了分享后执行的回调函数
            },
          };

          let that = this;
          let shareFunction = function () {
            //需在用户可能点击分享按钮前就先调用
            wx.updateAppMessageShareData(that.shareOption);
            wx.updateTimelineShareData(that.shareOption);
          };

          //加载微信配置
          wxConfig(shareFunction);
        });
      },
      reload() {
        location.reload();
      },
    },
  };
</script>

<style scoped lang="less">
  .testBtn {
    display: inline-flex;

    div {
      padding: 0 5px;
    }

  }

  .popupBox1 {
    margin-top: 76%;
    margin-left: 37%;
    width: 170px;
    height: 170px;
    background: none;
  }

  .popupBox2 {
    margin-top: 20px;
    width: 270px;
    height: 290px;
    text-align: center;
  }

  .popupBox2Img {
    margin-top: 30px;
    width: 200px;
    height: 200px;
    text-align: center;
  }

  .m16 {
    text-align: center;
    height: 20px;
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 5px;
  }

  .m17 {
    text-align: center;
    height: 36px;
    font-size: 12px;
    color: #999999;
    line-height: 17px;
  }
</style>